import styled from "styled-components";
import {Button} from "semantic-ui-react";

export const StyledDLVTemplate = styled.div`
  display: flex;

  .ui.inverted.menu {
    box-shadow: 2px 1px 5px 1px rgb(0 0 0 / 80%);
    background-color: transparent;
  }

  .small-logo {
    margin: auto;
  }

  .pusher {
    display: flex;
    min-height: calc(100vh - 20px);
  }
  
  .small-bar {
    width: 52px;
    background-color: #f3f8ff;
    box-shadow: 2px 1px 2px 0 rgb(34 36 38 / 15%);
    margin-right: 3px;

    .small-bar-inner {
      width: 54px;
      height: 100%;
      padding: 10px 0;
    }
  }

  .small-bar-inner {
    &:hover {
      cursor: pointer;
    }

    i {
      display: block;
      padding: 14px 14px 29px;
      color: #d27e01;

      &:first-child {
        background-color: #fff;
        border-bottom: 1px solid #d8e4f4;
        color: #084d86;
      }
    }
  }

  .ui.segment.pushable {
    display: flex;
    max-height: 100vh;
    overflow: hidden;
  }

  .ui.inverted.vertical.labeled.icon.ui.uncover.left.wide.visible.sidebar.menu {
    display: contents;
  }

  .ui.visible.wide.left.sidebar ~ .fixed, .ui.visible.wide.left.sidebar ~ .pusher {
    transform: none !important;
  }

  .full-width {
    width: calc(100vw - 380px);
  }

  .collapsed-filters {
    width: 100vw;
  }
`;

export const StyledCollapseButton = styled(Button)`
  &.collapse-button {
    padding: 4px 7px;
    position: absolute;
    left: 46px;
    top: 18px;
    background: transparent;
    font-size: 16px;

    &:hover, &:active, &:focus {
      background-color: transparent;
    }
  }
`;