import styled from "styled-components";
import {Button, Header} from "semantic-ui-react";
import {DatePicker} from "rsuite";

export const StyledFilters = styled.div`
  margin: 0 3px;
  box-shadow: 2px 1px 2px 0 rgb(34 36 38 / 15%);
  background-color: #f3f8ff;
  width: 380px;
  padding-bottom: 10px;

  .logo {
    .env-label {
      text-align: left;
      color: rgb(8, 77, 134);
      font-weight: 600;
      font-size: 14px;
      padding-top: 10px;
      padding-left: 6px;
      display: flex;

      span {
        color: #575757;
        font-weight: 200;
        padding-left: 5px;
      }
    }
  }

  .ui.checkbox input:checked ~ .box:before, .ui.checkbox input:checked ~ label:before {
    background: #fff;
    border: 2px solid #2b931b;
  }

  .ui.radio.checkbox input:checked ~ .box:after, .ui.radio.checkbox input:checked ~ label:after {
    background-color: transparent;
  }

  .filter-header {
    background-color: #fff;
    padding: 15px 20px;
    border-bottom: 1px solid #d8e4f4;
    width: 380px;
  }

  img {
    width: 210px;
  }

  .environment {
    padding-top: 15px;
  }

  .filters {
    padding: 15px 20px 0;
    width: 380px;
  }

  .messages {
    border-bottom: 1px dotted #d8e4f4;

    .ui.form {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;

      .field {
        margin: 0;
      }
    }
  }

  .date-range {
    padding: 0 0 10px;
    border-bottom: 1px dotted #d8e4f4;

    .date-time-label {
      white-space: nowrap;
      display: flex;
      align-items: center;
      min-width: 64px;
      margin-right: 10px;
    }

    .start, .end {
      display: flex;

      .date-filter {
        margin-right: 10px;
      }
    }
  }

  .date-time-container {
    position: relative;

    .custom-icon {
      position: absolute;
      right: 13px;
      top: 21px;
      z-index: 9;
      width: 13px;
      pointer-events: none;
    }
  }

  .sender-recipient {
    padding: 0 0 10px;
    border-bottom: 1px dotted #d8e4f4;

    .multiple-value-text-input-item-container p {
      padding-bottom: 8px;
      margin-top: -8px;
    }

    .ui.input {
      padding: 10px 0;
      width: 100%;
    }

    .ui.form {
      display: flex;
      padding: 0;
      margin: 5px 0 10px;

      .field {
        margin: 0;
      }
    }

    .pdn-left-30 {
      padding-left: 30px;
    }

    .validation-error {
      text-align: left;
      display: block;
      top: -5px;
      position: relative;
      color: #9f3a38;
    }
  }

  .rest-filters {
    .ui.dropdown {
      &:first-child {
        margin: 20px 0 0;
      }

      margin: 5px 0 0;
    }

    .ui.input {
      margin: 5px 0 0;
      width: 100%;
    }
  }

  .filters-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    margin-bottom: 10px;

    .clear {
      background-color: transparent;
      color: #084d86;
      padding: 0 5px;
      border: 1px solid transparent;
      &:focus {
        border-color: #3498ff;
        box-shadow: 0 0 0 3px rgb(52 152 255 / 25%);
        transition: border-color .1s ease, box-shadow .1s ease;
      }
    }
    .apply {
      border: 1px solid transparent;
      &:focus {
        border-color: #fff;
        box-shadow: 0 0 0 3px rgb(52 152 255 / 25%);
        transition: border-color .1s ease, box-shadow .1s ease;
      }
    }
  }

  .performance-slows {
    color: #E48900;
    text-align: left;
  }

  .sidebar-component {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
  }

  .dropdownWithLoading {
    position: relative;
    display: grid;

    & > div:first-child {
      position: relative;
    }

    .lds-ring {
      width: 30px;
      height: 30px;

      div {
        width: 20px;
        height: 20px;
        border-width: 2px;
      }
    }
  }
`;

export const StyledHeader = styled(Header)`
  text-align: left;
  margin-bottom: 0;
  color: #084d86;
  font-weight: 600;
  font-size: 14px;
  padding-top: 10px;
`;

export const StyledDateRangePicker = styled(DatePicker)`
  padding: 10px 0 5px;
  width: 100%;
  &.error {
    .rs-btn.rs-btn-default {
      background-color: #fff6f6 !important;
      border-color: #e0b4b4 !important;
      color: #9f3a38 !important;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &.time-filter svg.rs-picker-toggle-caret.rs-icon {
    display: none;
  }

`;

export const StyledCollapseButton = styled(Button)`
  &.collapse-button {
    padding: 4px 7px;
    position: absolute;
    left: 370px;
    top: 15px;
    background: transparent;
    font-size: 16px;

    &:hover, &:active, &:focus {
      background-color: transparent;
    }
  }
`;
