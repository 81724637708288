export const KEYBOARDS_BUTTONS = {
    ARROW_RIGHT_KEY: 39,
    ARROW_LEFT_KEY: 37,
    TAB_KEY: 9,
    ENTER_KEY: 13
}
export const IGNORE_TOOLTIP_TEXT =
    <>
        <p>A minimum of <strong>3 characters</strong>  is required to query the Sender & Recipient fields.</p>
        <p>Enter will submit the provided characters. Users can add single or multiple entries.</p>
        <p>Use a <strong>hyphen</strong> immediately before the text to indicate that you do not want results that
            contain this text to appear in your results.</p>
    </>
