import * as React from 'react';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Button, Checkbox, Icon, Modal} from 'semantic-ui-react'
import {Export} from "../../../types/exportType";
import {CheckboxProps} from "semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox";
import {getCSVFile} from "../../../services/API";
import {Filters} from "../../../types/filtersType";
import {apiCSVFileUrl} from "../../../services/CONSTANTS";
import {getAllMessagesBody} from "../../../services/allMessagesBodyService";

const StyledButton = styled(Button)`
  display: flex !important;

  span {
    padding-left: 10px;
  }
`;

const StyledModal = styled(Modal)`
  &.ui.modal > .actions {
    display: flex;
    justify-content: flex-end;
  }

  &.ui.modal > .content {
    background-color: rgba(33, 133, 208, 0.08);

    label {
      color: rgb(21, 98, 155);
    }
  }

  .deselectButton {
    margin-top: 20px;
    background: transparent;
    color: rgb(33, 133, 208);

    &:active, &:focus {
      background: transparent;
      color: rgb(33, 133, 208);
    }

  }
  &.ui.modal > .close {
    top: 1.0535rem;
    right: 1rem;
    color: rgba(0,0,0,.87);
  }
`;

const StyledCheckboxContainer = styled.div`
  display: flex;

  .checkboxCol {
    display: flex;
    flex-direction: column;
    width: 33%;

    .ui.checkbox {
      padding: 10px;
    }
  }

  .ui.checkbox input:checked ~ .box:before, .ui.checkbox input:checked ~ label:before {
    border-color: #b8cbe5;
  }

  .ui.checkbox input:checked ~ .box:after, .ui.checkbox input:checked ~ label:after {
    color: #db8400;
  }
`;

const defaultExportValue: Export = {
    updateTimestamp: true,
    messageStartTimestamp: true,
    sender: true,
    recipient: true,
    disposition: true,
    state: true,
    messageErrorId: false,
    xdMessageId: true,
    smtpMessageId: true
}

type ExportFileProps = {
    filters: Filters
}

export const ExportFileComponent = ({filters}: ExportFileProps) => {

    const [open, setOpen] = useState(false);
    const [selectedExport, setSelectedExport] = useState(defaultExportValue);
    const [selectedAllButton, setSelectedAllButton] = useState(true);
    const [noValueSelected, setNoValueSelected] = useState(false);

    useEffect(() => {
        const allUncheck = [];
        for (const exportColumn in selectedExport) {
            if (!selectedExport[exportColumn as keyof Export]) {
                allUncheck.push(exportColumn);
            }
        }
        if (allUncheck.length) {
            setSelectedAllButton(true);
        } else {
            setSelectedAllButton(false);
        }
        /**
         * Count of all uncheck value === 9
         */
        setNoValueSelected(allUncheck.length !== 9)
    }, [selectedExport, setSelectedExport]);

    const exportFile = () => {
        const keys = Object.keys(selectedExport);
        const selectedColumns: Export | string[] = [];
        keys.forEach((value) => {
            if (selectedExport[value as keyof Export]) {
                selectedColumns.push(value);
            }
        })
        getCSVFile(apiCSVFileUrl(), getAllMessagesBody(filters), selectedColumns).then(() => {
            setSelectedExport(defaultExportValue);
            setSelectedAllButton(true);
            setOpen(false);
        });
    }

    const selectExportColumn = (event: React.SyntheticEvent, data: CheckboxProps) => {
        if (data?.name) {
            setSelectedExport((prevState) => {
                return {
                    ...prevState,
                    [data.name ? data.name : "key"]: data?.checked
                }
            });
        }
    }

    const deselectSelectAll = (event: React.SyntheticEvent) => {
        /**
         * messageErrorId should be false according to https://medallies.atlassian.net/browse/DEV-5262
         */
        // @ts-ignore
        event?.currentTarget?.blur();
        setSelectedExport({
            updateTimestamp: selectedAllButton,
            messageStartTimestamp: selectedAllButton,
            sender: selectedAllButton,
            recipient: selectedAllButton,
            disposition: selectedAllButton,
            state: selectedAllButton,
            messageErrorId: false,
            xdMessageId: selectedAllButton,
            smtpMessageId: selectedAllButton
        })

    }

    return (
        <StyledModal
            closeIcon
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <div>
                    <StyledButton positive data-cy="export-button">
                        <Icon fitted name='upload'/>
                        <span>Export</span>
                    </StyledButton>
                </div>
            }
        >
            <Modal.Header>Export</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <StyledCheckboxContainer>
                        <div className="checkboxCol">
                            <Checkbox label='Send Time' name='updateTimestamp'
                                      checked={selectedExport.updateTimestamp} onClick={selectExportColumn}/>
                            <Checkbox label='Start Time' name='messageStartTimestamp'
                                      checked={selectedExport.messageStartTimestamp} onClick={selectExportColumn}/>
                            <Checkbox label='Sender' name='sender' checked={selectedExport.sender}
                                      onClick={selectExportColumn}/>

                        </div>
                        <div className="checkboxCol">
                            <Checkbox label='Recipient' name='recipient' checked={selectedExport.recipient}
                                      onChange={selectExportColumn}/>
                            <Checkbox label='Disposition Type' name='disposition'
                                      checked={selectedExport.disposition}
                                      onChange={selectExportColumn}/>
                            <Checkbox label='Msg State' name='state' checked={selectedExport.state}
                                      onChange={selectExportColumn}/>
                        </div>
                        <div className="checkboxCol">
                            {/*Should be removed according to https://medallies.atlassian.net/browse/DEV-5262*/}
                            {/*<Checkbox label='Failure Reason' name='messageErrorId'*/}
                            {/*          checked={selectedExport.messageErrorId}*/}
                            {/*          onChange={selectExportColumn}/>*/}
                            <Checkbox label='XD Msg ID' name='xdMessageId' checked={selectedExport.xdMessageId}
                                      onChange={selectExportColumn}/>
                            <Checkbox label='SMTP Msg ID' name='smtpMessageId'
                                      checked={selectedExport.smtpMessageId} onChange={selectExportColumn}/>
                        </div>
                    </StyledCheckboxContainer>
                    {!selectedAllButton ? (
                        <Button onClick={deselectSelectAll} data-cy="deselect-button" className='deselectButton'>Deselect
                            All</Button>
                    ) : (
                        <Button onClick={deselectSelectAll} data-cy="select-button" className='deselectButton'>Select
                            All</Button>
                    )}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <div style={{
                    visibility: noValueSelected ? "visible" : "hidden",
                }}>
                    <Button
                        data-cy="export-modal-button"
                        onClick={exportFile}
                        positive>
                        <span>Export</span>
                    </Button>
                </div>

            </Modal.Actions>
        </StyledModal>
    );
};
