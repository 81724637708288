import styled from "styled-components";
import { SenderRecipientMultivalueInput } from '../../pages/DistilledLogViewer/components/filter-components/SenderRecipentMultivalueInput'

export const StyledFocusMultipleValueInput = styled(SenderRecipientMultivalueInput)`
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
  
    input {
      margin-top: 5px;
    }

    div.rs-tag {
      margin: 5px 5px 0 0;
      border-radius: 0.28571429rem;
      background: transparent !important;
      border: 1px solid rgba(34,36,38,.15);
      font-size: 14px;
    }

  input.custom-multiple-input:focus {
    border-color: #3498ff;
    box-shadow: 0 0 0 3px rgb(52 152 255 / 25%);
    transition: border-color .3s ease-in-out;
  }

  input.error {
    background-color: #fff6f6 !important;
    border-color: #e0b4b4 !important;
    color: #9f3a38 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  } 
`;