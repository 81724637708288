import moment from 'moment';
import {Filters, SenderRecipient} from "../types/filtersType";

export const getAllMessagesBody = (filters: Filters) => {
    if (filters?.date?.length != 2 || filters?.time?.length != 2) {
        return null;
    }
    return {
        ...dateParse(filters.date, filters.time),
        senderRecipient: filters.senderRecipient ? setExcludeIncludeProperties(filters.senderRecipient) : {},
        xdMessageId: filters.xdMessageId ? filters.xdMessageId : null,
        smtpMessageId: filters.smtpMessageId ? filters.smtpMessageId : null,
        failureReason: filters.failureReason ? filters.failureReason : null,
        ...processDispositions(filters.dispositions),
        states: filters?.states?.length ? filters.states : [],
        offset: filters.offset,
        limit: filters.limit
    }
}

function dateParse(date: (Date | null)[], time: (Date | null)[]) {
    // @ts-ignore
    const startDate = new Date(date[0].setHours(time[0].getHours(), time[0].getMinutes()))
    // @ts-ignore
    const endDate = new Date(date[1].setHours(time[1].getHours(), time[1].getMinutes()));
    return {
        sendNotBefore: convertDateTime(startDate),
        sendNotAfter: convertDateTime(endDate)
    }
}

function convertDateTime(date: Date) {
    return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
}

export const convertDateToLocal = (data: Date) => {
    const date = moment.utc(data).format('YYYY-MM-DD HH:mm:ss.SSS');
    const stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss.SSS');
}


function processDispositions(data: string[]) {
    const result = data?.map(function (x) {
        return x.replace('EMPTY', '');
    });
    return {
        dispositions: result?.length ? result : []
    }
}

const setExcludeIncludeProperties = (senderRecipient: SenderRecipient) => {
    const senderRecipientObject: SenderRecipient = {
        senders: [],
        recipients: [],
        and: senderRecipient.and,
        sendersExclude: [],
        recipientsExclude: []
    };
    senderRecipient.senders.forEach((value) => {
        if (value.charAt(0) === '-') {
            senderRecipientObject.sendersExclude.push(value.substring(1));
        } else {
            senderRecipientObject.senders.push(value);
        }
    });
    senderRecipient.recipients.forEach((value: string) => {
        if (value.charAt(0) === '-') {
            senderRecipientObject.recipientsExclude.push(value.substring(1));
        } else {
            senderRecipientObject.recipients.push(value);
        }
    });
    return senderRecipientObject;
}
