import styled from "styled-components";
import {Dropdown} from "semantic-ui-react";

export const StyledFocusDropdown = styled(Dropdown)`
  &.ui.selection.dropdown:focus {
    border-color: #3498ff;
    box-shadow: 0 0 0 3px rgb(52 152 255 / 25%);
  }

  &.ui.selection.dropdown:focus .visible.menu {
    border-color: #3498ff;
    box-shadow: 0 1px 0 3px rgb(52 152 255 / 25%);
  }
`;