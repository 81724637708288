import React from "react";
import DistilledLogViewerView from "./DistilledLogViewerView";

export const DistilledLogViewerContainer = () => {
    return (
        <div>
            <DistilledLogViewerView/>
        </div>
    );
}
