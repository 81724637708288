import {atom} from "recoil";
import {defaultFilterValue} from "../services/defauldFilterObject";
import {Filters} from "../types/filtersType";

export const filterState = atom<Filters>({
    key: 'filterState',
    default: defaultFilterValue
});

export const sideBarFilterState = atom<Filters>({
    key: 'sideBarFilterState',
    default: defaultFilterValue
});
