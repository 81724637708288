import React from 'react';
import {Icon, Popup} from 'semantic-ui-react'
import styled from "styled-components";

const StyledPopup = styled(Popup)`
  p {
    margin-top: 0;
  }
`;
const InfoPopup = (content) => (

    <StyledPopup
        content={content}
        trigger={<Icon name='info circle' style={{marginLeft: '3px', color: '#555'}} />}
        position='right center'
        basic={false}
        wide='very'
        data-cy='tooltip-popup'
    />
)

export default InfoPopup;
