import _ from 'lodash';
import { useReducer } from 'react';

import PropTypes from "prop-types";

import { useRecoilValue } from "recoil";
import { Icon, Table } from 'semantic-ui-react';
import { ErrorComponent } from "../../../components/ErrorComponent";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { convertDateToLocal } from "../../../services/allMessagesBodyService";
import { createErrorObject } from "../../../services/errorService";
import { filterState } from "../../../states/filtersState";
import { TableHeaderComponent } from "./TableHeaderComponent";
import { TablePaginationComponent } from "./TablePaginationComponent";
import { StyledComponentTable, TableCustomStyles, TableStyles } from "./style/table-component-styling";

function sortingReducer(state, action) {
    switch (action.type) {
        case 'CHANGE_SORT':
            if (state.column === action.column) {
                return {
                    ...state,
                    data: state.data.slice().reverse(),
                    direction: state.direction === 'ascending' ? 'descending' : 'ascending',
                }
            }

            return {
                column: action.column, data: _.sortBy(state.data, [action.column]), direction: 'ascending',
            }
        default:
            throw new Error()
    }
}

export const TableComponent = ({
                                   tableMessages,
                                   toggleSidebar,
                                   details,
                                   loading,
                                   numberOfPages,
                                   numberOfRecords,
                                   fetchMessageDetails
                               }) => {
    const [state, dispatch] = useReducer(sortingReducer, {
        column: null, data: tableMessages, direction: null,
    })

    const filters = useRecoilValue(filterState);

    const {column, direction} = state
    let error = createErrorObject('No Results', 'Update your search to see more options')
    const selectRow = (data) => {
        fetchMessageDetails(data.id)
    }
    if (tableMessages instanceof Error) {
        error = createErrorObject('Error Loading Messages', 'Please refresh your browser to try again')
    }

    const copyId = (event, id) => {
        event.stopPropagation();
        navigator.clipboard.writeText(id);
    }
    return (<StyledComponentTable>
        <TableHeaderComponent toggleSidebar={toggleSidebar} numberOfRecords={numberOfRecords} filters={filters}/>
        {loading ? <LoadingSpinner/> : tableMessages.length ? <TableCustomStyles>
            <TableStyles sortable celled basic='very' singleLine={false}>
                <Table.Header className="top-header">
                    <Table.Row>
                        <Table.HeaderCell>
                            Send Time
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Start Time
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Sender
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Recipient
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'updateTimestamp' ? direction : null}
                            onClick={() => dispatch({type: 'CHANGE_SORT', column: 'updateTimestamp'})}
                        >
                            Disposition Type
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Failure Reason
                        </Table.HeaderCell>
                        {/*<Table.HeaderCell>*/}
                        {/*    Msg State*/}
                        {/*</Table.HeaderCell>*/}
                        {/*<Table.HeaderCell*/}
                        {/*    sorted={column === 'errorCode' ? direction : null}*/}
                        {/*    onClick={() => dispatch({type: 'CHANGE_SORT', column: 'errorCode'})}*/}
                        {/*>*/}
                        {/*    Failure Reason*/}
                        {/*</Table.HeaderCell>*/}
                        <Table.HeaderCell>
                            XD Msg ID
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            SMTP Msg ID
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body data-cy="messages-table-body">
                    {tableMessages.map(({
                                            id,
                                            updateTimestamp,
                                            messageStartTimestamp,
                                            sender,
                                            recipient,
                                            error,
                                            disposition,
                                            smtpMessageId,
                                            xdMessageId
                                        }) => (<Table.Row key={id} onClick={() => selectRow({id})}
                                                          style={{backgroundColor: (id === details.id) ? '#fff0da' : ''}}>
                        <Table.Cell>
                            <div className='days'>
                                {updateTimestamp ? convertDateToLocal(updateTimestamp) : convertDateToLocal(messageStartTimestamp)}
                            </div>
                        </Table.Cell>
                        <Table.Cell>
                            <div className='days'>
                                {convertDateToLocal(messageStartTimestamp)}
                            </div>
                        </Table.Cell>
                        <Table.Cell>{sender}</Table.Cell>
                        <Table.Cell>{recipient}</Table.Cell>
                        <Table.Cell
                            style={{color: disposition === "FAILED" ? '#b60f36' : ''}}>{disposition}</Table.Cell>
                        {/*<Table.Cell>{state}</Table.Cell>*/}
                        <Table.Cell>{error?.errorCode ? error?.errorCode : ''}</Table.Cell>
                        <Table.Cell className="copy-icon-cell">
                            {xdMessageId ?
                                <>
                                    {xdMessageId}
                                    <span>
                                        <Icon className="copy-icon" fitted name='copy outline'
                                              onClick={(event) => copyId(event, xdMessageId)}/>
                                    </span>
                                </> : <></>}
                        </Table.Cell>
                        <Table.Cell className="copy-icon-cell">
                            {smtpMessageId ?
                                <>
                                    {smtpMessageId}
                                    <span>
                                        <Icon className="copy-icon" fitted name='copy outline'
                                              onClick={(event) => copyId(event, smtpMessageId)}/>
                                    </span>
                                </> : <></>}
                        </Table.Cell>
                    </Table.Row>))}
                </Table.Body>
            </TableStyles>
        </TableCustomStyles> : <ErrorComponent classProperty={'no-results'} error={error}/>}
        {(!loading && tableMessages.length) ?
            <TablePaginationComponent numberOfPages={numberOfPages}/> : ''}
    </StyledComponentTable>);
};

TableComponent.propTypes = {
    tableMessages: PropTypes.array.isRequired,
    toggleSidebar: PropTypes.bool.isRequired,
    setDetails: PropTypes.func.isRequired,
    fetchMessageDetails: PropTypes.func.isRequired,
    details: PropTypes.object.isRequired
}
