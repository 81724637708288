const gcpProjectIdToEnv = new Map();
gcpProjectIdToEnv.set("sandbox", "ma-cloud-sandbox");
gcpProjectIdToEnv.set("dev", "ma-cloud-development");
gcpProjectIdToEnv.set("uat", "gcp-ma-uat-000");
gcpProjectIdToEnv.set("val", "gcp-ma-val-000");
gcpProjectIdToEnv.set("prod", "gcp-ma-prod-000");

export const apiFiltersUrl = (env = null) => {
    return constructUrl('filter', env)
}

export const apiMessageByIdUrl = (env = null, id: number) => {
    return constructUrl(`${id}`, env)
}

export const getGcpProjectId = () => {
    return gcpProjectIdToEnv.get(process.env.REACT_APP_APP_ENV);
}

export const apiStatesUrl = () => {
    return constructUrl('states', null);
}
export const apiDispositionsUrl = () => {
    return constructUrl('dispositions', null);
}

export const apiCSVFileUrl = () => {
    return constructUrl('export', null);
}

export const apiAttachmentUrl = (id: number) => {
    return constructUrl(`attachments/download/${id}`, null)
}

const constructUrl = (url: string, environment: string | null) => {
    const env = environment ? environment : process.env.REACT_APP_APP_ENV;
    if (env === 'prod') {
        return 'https://api.medallies.com/asm/messages/'.concat(url);
    }
    return `https://api.${env}.medallies.com/asm/messages/`.concat(url);
}
