import React, {useState} from "react";
import styled from 'styled-components';
import {StyledFocusInput} from "../styles/styled-components/styled-focus-input";
import {Filters} from "../types/filtersType";

const StyledTextInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

type TextInputProps = {
    inputLabel: string,
    dataTestAttr: string,
    dataTabIndex: string,
    dataValue: string,
    setSidebarFilters: (value: (prevState: Filters) => Filters) => void,
    sidebarFilters: Filters
}

export const TextInput = ({
                              inputLabel,
                              dataTestAttr,
                              dataTabIndex,
                              dataValue,
                              setSidebarFilters,
                              sidebarFilters
                          }: TextInputProps) => {
    const [value, setValue] = useState(sidebarFilters[dataValue as keyof Filters] as string);

    const setFilterDate = (data: string[], selector: string) => {
        if (data?.length) {
            setSidebarFilters(prevState => ({...prevState, [selector]: data[0]}));
        } else {
            setSidebarFilters(prevState => ({...prevState, [selector]: null}));
        }
    }

    function handleChange(event: React.FormEvent<HTMLInputElement>) {
        setValue(event?.currentTarget?.value);
        setFilterDate([event?.currentTarget?.value], dataValue);
    }

    return (
        <StyledTextInput>
            <StyledFocusInput data-cy={dataTestAttr} tabIndex={dataTabIndex}
                              onChange={handleChange}
                              placeholder={inputLabel}
                              value={value}/>
        </StyledTextInput>
    );
}