import { useEffect, useState } from 'react';
import { useRecoilValue } from "recoil";
import { Icon, Image, Menu, Segment, Sidebar, } from 'semantic-ui-react';
import { getAllMessages, getDropdownComponents, getMessage } from "../../services/API";
import { apiDispositionsUrl, apiFiltersUrl, apiMessageByIdUrl, apiStatesUrl } from "../../services/CONSTANTS";
import { getAllMessagesBody } from "../../services/allMessagesBodyService";
import { createOptionObject } from "../../services/dropdownOptionService";
import { filterState } from "../../states/filtersState";
import logoSmall from './../../assets/MedAllies_Logo_vector-small.svg';
import { DetailsSideBar } from "./components/DetailsSideBar";
import { FilterComponent } from "./components/FilterComponent";
import { TableComponent } from "./components/TableComponent";
import { StyledCollapseButton, StyledDLVTemplate } from "./components/style/dlv-component-styling";

const DistilledLogViewerView = () => {
    const [data, setData] = useState([]);
    const [numberOfPages, setNumberOfPages] = useState();
    const [numberOfRecords, setNumberOfRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [dropdownLoading, setDropdownLoading] = useState(true);
    const [sideBarLoading, setSideBarLoading] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const [details, setDetails] = useState({});
    const [states, setStates] = useState([]);
    const [dispositions, setDispositions] = useState([]);
    const filters = useRecoilValue(filterState);

    useEffect(() => {
        fetchMessages(filters).then(() => setLoading(false));
    }, [filters]);
    useEffect(() => {
        getDropdownValues().then(() => setDropdownLoading(false));
    }, []);

    async function fetchMessages(filtersBody) {
        setLoading(true);
        const env = process.env.REACT_APP_APP_ENV;
        let messagesResponse = await getAllMessages(apiFiltersUrl(env), getAllMessagesBody(filtersBody));
        messagesResponse = await messagesResponse;
        if (messagesResponse) {
            setData(messagesResponse.data);
            setNumberOfPages(messagesResponse.paging.numberOfPages);
            setNumberOfRecords(messagesResponse.paging.numberOfRecords);

        } else {
            setData(new Error());
            setNumberOfRecords(0);
        }
    }

    async function fetchMessageDetails(messageId) {
        setSideBarLoading(true);
        const env = process.env.REACT_APP_APP_ENV;
        let messagesResponse = await getMessage(apiMessageByIdUrl(env, messageId));
        messagesResponse = await messagesResponse;
        if (messagesResponse) {
            setDetails(messagesResponse);
        } else {
            setData(new Error());
            setNumberOfRecords(0);
        }
        setSideBarLoading(false);
    }

    async function getDropdownValues() {
        setDropdownLoading(true);
        const env = process.env.REACT_APP_APP_ENV;
        let statesUrl = apiStatesUrl(env);
        let dispositionsUrl = apiDispositionsUrl(env);
        let stateResponse = getDropdownComponents(statesUrl);
        let dispositionResponse = getDropdownComponents(dispositionsUrl);
        await Promise.all([stateResponse, dispositionResponse])
        stateResponse = await stateResponse;
        dispositionResponse = await dispositionResponse;
        const statesOptions = [];
        const dispositionsOptions = [];
        stateResponse?.forEach(state => statesOptions.push(createOptionObject(state.messageState)));
        setStates(statesOptions);
        populateDispositionOptions(dispositionResponse, dispositionsOptions);
        setDispositions(dispositionsOptions);
        setDropdownLoading(false);
    }

    function populateDispositionOptions(dispositionResponse, dispositionsOptions) {
        dispositionResponse?.forEach(disposition => {
            disposition.disposition === '' ? dispositionsOptions.push(createOptionObject("EMPTY")) : dispositionsOptions.push(createOptionObject(disposition.disposition))
        });
    }


    const sidebarToggle = () => {
        setToggleSidebar(prevState => (!prevState));
    }

    const VerticalSidebar = ({animation, direction, visible}) => (<Sidebar
        as={Menu}
        animation={animation}
        direction={direction}
        icon='labeled'
        inverted
        vertical
        visible={visible}
        width='wide'
    >
        <FilterComponent setToggleSidebar={setToggleSidebar} states={states}
                         dispositions={dispositions} dropdownLoading={dropdownLoading}/>
    </Sidebar>);
    return (<StyledDLVTemplate>
        <Sidebar.Pushable as={Segment}>

            <VerticalSidebar
                animation='uncover'
                direction='left'
                visible={toggleSidebar}
            />
            <Sidebar.Pusher className={toggleSidebar ? 'full-width' : 'collapsed-filters'}>
                {!toggleSidebar ? <div className='small-bar' onClick={sidebarToggle}>
                    <div className='small-bar-inner'>
                        <Image className='small-logo' src={logoSmall} size='mini'/>
                        <Icon fitted name='filter'/>
                    </div>
                    <StyledCollapseButton className='collapse-button'>
                        <Icon fitted name='caret right'/>
                    </StyledCollapseButton>
                </div> : ''}
                <TableComponent tableMessages={data} toggleSidebar={toggleSidebar} setDetails={setDetails}
                                details={details} loading={loading}
                                numberOfPages={numberOfPages} numberOfRecords={numberOfRecords} fetchMessageDetails={fetchMessageDetails}/>
            </Sidebar.Pusher>
            <DetailsSideBar data={details} setDetails={setDetails} loading={sideBarLoading}/>
        </Sidebar.Pushable>

    </StyledDLVTemplate>)
}

export default DistilledLogViewerView
