import * as React from 'react';
import styled from 'styled-components';
import {Icon, Menu} from 'semantic-ui-react'
import {useRecoilState} from "recoil";
import {filterState} from "../../../states/filtersState";

const StyledTablePagination = styled.div`

  .pagination.menu {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0 none !important;
    box-shadow: none;
  }

  .pagination .pagination-summary {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;
    color: #084d86;
  }

  .pagination .item {
    padding: 0;
  }

  .pagination .disabled .caret {
    color: rgba(40, 40, 40, .3);
  }

  .pagination .caret {
    color: #084D86;
  }

  .ui.menu {
    min-height: 0 !important;
  }
`;

const ChangePageMenuItem = ({ pagingDirection, isDisabled, pageChangeHandler }) => {
  return (
    <Menu.Item name={pagingDirection} as='a' icon disabled={isDisabled} onClick={pageChangeHandler}>
      <Icon name={'caret ' + pagingDirection} size='big' />
    </Menu.Item>
  );
}

export const TablePaginationComponent = ({numberOfPages}) => {
  const [filters, setFilters] = useRecoilState(filterState);
  const handlePageChange = (e, data) => {
    e.preventDefault();
    switch (data.name) {
      case 'right':
        setFilters(prevState => ({...prevState, offset: filters.offset + 1}));
        break;
      case 'left':
        setFilters(prevState => ({...prevState, offset: filters.offset - 1}));
        break;
      default:
        throw new Error("Invalid paging direction");

    }
  }

  return (
      <StyledTablePagination>
        {(numberOfPages > 1) &&
            <Menu pagination borderless>
              <ChangePageMenuItem pagingDirection={'left'} pageChangeHandler={handlePageChange}
                                  isDisabled={filters.offset === 0}/>
              <Menu.Item className='pagination-summary'
                         active={false}>{filters.offset + 1} of {numberOfPages}</Menu.Item>
              <ChangePageMenuItem pagingDirection={'right'} pageChangeHandler={handlePageChange}
                                  isDisabled={filters.offset === numberOfPages - 1}/>
            </Menu>
        }
      </StyledTablePagination>
  );
}
