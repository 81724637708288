import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {StyledDateRangePicker, StyledHeader} from "../style/filter-component-styling";
import {Icon} from "semantic-ui-react";
import {after} from "rsuite/esm/DateRangePicker/disabledDateUtils";
import TimeIcon from "@rsuite/icons/Time";
import {allowedRange} from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import {addDays} from 'date-fns';
import {useRecoilState} from "recoil";
import {sideBarFilterState} from "../../../../states/filtersState";

export const FilterDateTimeComponent = () => {
    const [openStartDate, setOpenStartDate] = useState(false);
    const [openEndDate, setOpenEndDate] = useState(false);
    const [sidebarFilters, setSidebarFilters] = useRecoilState(sideBarFilterState);

    const handleClickOutside = useCallback((event: MouseEvent | EventTarget) => {
        const elements = document.querySelector(".rs-picker-date-menu.rs-picker-menu");
        // @ts-ignore
        if (elements && !elements.contains(event.target)) {
            setOpenStartDate(false);
            setOpenEndDate(false);
        }
    }, [])

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const startDateRanges = [
        {
            label: 'today',
            value: new Date(),
            closeOverlay: true
        },
        {
            label: 'yesterday',
            value: addDays(new Date(), -1),
            closeOverlay: true
        },
        {
            label: 'Last 7 days',
            value: addDays(new Date(), -6),
            closeOverlay: true
        }
    ];

    const selectStartTime = (data: string | Date | React.MouseEvent) => {
        const selectedTime = [...sidebarFilters.time];
        if (typeof data === "string" || data instanceof Date) {
            selectedTime[0] = new Date(data);
        } else {
            selectedTime[0] = null;
        }
        setFilterData(selectedTime, 'time');

    }

    const selectEndTime = (data: string | Date | React.MouseEvent) => {
        const selectedTime = [...sidebarFilters.time];
        if (typeof data === "string" || data instanceof Date) {
            selectedTime[1] = new Date(data);
        } else {
            selectedTime[1] = null;
        }
        setFilterData(selectedTime, 'time');
    }

    const setFilterData = (data: (Date | null)[], selector: string) => {
        setSidebarFilters(prevState => ({...prevState, [selector]: data}));
    }

    const isValidDate = (date: Date | null | undefined) => {
        return date?.valueOf();
    }

    const closeStartDateCalendar = () => {
        setOpenStartDate(prevState => {
            if (!prevState) {
                setOpenEndDate(false);
            }
            return !prevState
        });
    }

    const closeEndDateCalendar = () => {
        setOpenEndDate(prevState => {
            if (!prevState) {
                setOpenStartDate(false);
            }
            return !prevState
        });
    }

    const onSelectStartDate = (date: string) => {
        const selectedDate = [...sidebarFilters.date];
        selectedDate[0] = new Date(date);
        setFilterData(selectedDate, 'date');
        setOpenStartDate((prevState: boolean) => (!prevState))

    }

    const onSelectEndDate = (data: string) => {
        const selectedDate = [...sidebarFilters.date];
        selectedDate[1] = new Date(data);
        setFilterData(selectedDate, 'date');
        setOpenEndDate((prevState: boolean) => (!prevState))
    }

    const onCleanStartDate = () => {
        const selectedDate = [...sidebarFilters.date];
        selectedDate[0] = null;
        setFilterData(selectedDate, 'date');
    }

    const onCleanEndDate = () => {
        const selectedDate = [...sidebarFilters.date];
        selectedDate[1] = null;
        setFilterData(selectedDate, 'date');
    }

    return (
        <div className='date-range'>
            <StyledHeader as='h6'>Date Range</StyledHeader>
            <div className="start">
                <div className="date-time-label"><p>Start Time</p></div>
                <StyledDateRangePicker format="yyyy-MM-dd" onClean={onCleanStartDate}
                    // @ts-ignore
                                       shouldDisableDate={after(sidebarFilters?.date[1])}
                                       calendarDefaultDate={sidebarFilters?.date[0]}
                                       defaultValue={sidebarFilters?.date[0]}
                                       value={isValidDate(sidebarFilters?.date[0]) ? sidebarFilters?.date[0] : null}
                                       data-cy="date-picker-start" tabindex="1"
                                       onClick={closeStartDateCalendar}
                                       onSelect={onSelectStartDate}
                                       open={openStartDate}
                                       ranges={startDateRanges}
                                       className={`date-filter ${isValidDate(sidebarFilters?.date[0]) ? '' : 'error'}`}/>
                <div className="date-time-container">
                    <StyledDateRangePicker format="HH:mm" onOk={selectStartTime} onClean={selectStartTime}
                                           onSelect={selectStartTime}
                                           value={isValidDate(sidebarFilters?.time[0]) ? sidebarFilters?.time[0] : null}
                                           tabindex="2"
                                           ranges={[]}
                                           placement="bottomEnd"
                                           calendarDefaultDate={sidebarFilters?.time[0]} data-cy="time-picker-start"
                                           className={`time-filter ${isValidDate(sidebarFilters?.time[0]) ? '' : 'error'}`}/>
                    <TimeIcon className="custom-icon"/>
                </div>
            </div>
            <div className="end">
                <div className="date-time-label"><p>End Time</p></div>
                <StyledDateRangePicker format="yyyy-MM-dd" onClean={onCleanEndDate}
                    // @ts-ignore
                                       shouldDisableDate={allowedRange(sidebarFilters?.date[0], new Date())}
                                       defaultValue={sidebarFilters?.date[1]}
                                       value={isValidDate(sidebarFilters?.date[1]) ? sidebarFilters?.date[1] : null}
                                       onClick={closeEndDateCalendar}
                                       onSelect={onSelectEndDate}
                                       open={openEndDate}
                                       data-cy="date-picker-end" tabindex="3"
                                       className={`date-filter ${isValidDate(sidebarFilters?.date[1]) ? '' : 'error'}`}/>
                <div className="date-time-container">
                    <StyledDateRangePicker format="HH:mm" onOk={selectEndTime} onClean={selectEndTime}
                                           onSelect = {selectEndTime}
                                           value={isValidDate(sidebarFilters?.time[1]) ? sidebarFilters?.time[1] : null}
                                           tabindex="4"
                                           ranges={[]}
                                           placement="bottomEnd"
                                           calendarDefaultDate={sidebarFilters?.time[1]} data-cy="time-picker-end"
                                           className={`time-filter ${isValidDate(sidebarFilters?.time[1]) ? '' : 'error'}`}/>
                    <TimeIcon className="custom-icon"/>
                </div>
            </div>
            <p className='performance-slows'><Icon fitted name='warning sign'/> Performance slows for 5+
                days</p>
        </div>
    );
};
