import styled from "styled-components";
import {Checkbox} from "semantic-ui-react";

export const StyledFocusCheckbox = styled(Checkbox)`
  &.ui.checkbox input:focus + label:before {
    border-color: #3498ff;
    box-shadow: 0 0 0 3px rgb(52 152 255 / 25%);
  }


  &.ui.checkbox input:checked:focus + label:before {
    border-color: #2b931b;
  }

  &.ui.checkbox.checked input:checked + label::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    font-size: 14px;
    background: #2b931b;
    transition: all .2s;
    width: 9px;
    height: 9px;
    border-radius: 50%;
  }
`;