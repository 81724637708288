import styled from "styled-components";

export const MessageStyled = styled.div`
  align-items: center;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  &.no-results {
    position: static;
    display: block;
    min-height: 500px;
  }

  .error-container {
    margin: auto;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .header {
      color: #B8CBE5;
    }
  }
`