import * as React from 'react';
import {Input, Tag} from 'rsuite';
import {Filters, SenderRecipient} from "../../../../types/filtersType";
import {useRecoilState} from "recoil";
import {sideBarFilterState} from "../../../../states/filtersState";
import {useState} from "react";

type InputProps = {
    className?: string,
    placeholder: string,
    filterName: string,
    dataCy: string,
    isValid: boolean,
    inputClassName: string,
    tabIndex: number,
    setIsValid: (isValid: boolean) => void
}

export const SenderRecipientMultivalueInput = ({
                                                   className, placeholder, setIsValid, inputClassName, dataCy,
                                                   filterName, tabIndex, isValid
                                               }: InputProps) => {

    const [sidebarFilters, setSidebarFilters] = useRecoilState(sideBarFilterState);
    const [inputValue, setInputValue] = useState('');

    const handleFormValueChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
        return setInputValue(event?.currentTarget?.value);
    }

    const groupByValidity = (data: string[]) => {
        const invalidValues = data.filter(it => it.length < 3);
        return {
            false: invalidValues,
            true: data.filter(it => invalidValues.indexOf(it) === -1)
        }
    }

    const setState = (prevState: Filters, filterName: string, newState: Set<string>) => {
        return {
            ...prevState,
            senderRecipient: {
                ...prevState.senderRecipient,
                [filterName]: Array.from(newState)
            }
        };
    }

    const addToState = (newValues: string[]) => {
        setSidebarFilters((prevState: Filters) => {
            const currentFilterState = new Set<string>(getCurrentFilterState());
            newValues.forEach(it => currentFilterState.add(it));
            return setState(prevState, filterName, currentFilterState);
        });
    }

    const removeFromState = (valueToRemove: string) => {
        setSidebarFilters((prevState: Filters) => {
            const currentFilterState = new Set<string>(getCurrentFilterState());
            currentFilterState.delete(valueToRemove);
            return setState(prevState, filterName, currentFilterState);
        });
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const pressedKey = e.key;
        if (!isValid) {
            setIsValid(true);
        }
        if (pressedKey === "Enter" || pressedKey === " " || pressedKey === ",") {
            e.preventDefault();
            const enteredValues: string[] = Array.of(e.currentTarget?.value)
                .flatMap(it => it.split(" "))
                .map(it => it.trim());

            const filterValues = enteredValues.filter(it => it !== '');

            const groupedByValidity = groupByValidity(filterValues);

            addToState(groupedByValidity["true"]);

            if (groupedByValidity["false"].length > 0) {
                e.currentTarget.value = groupedByValidity["false"].join(" ");
                setIsValid(false);
            } else {
                e.currentTarget.value = e.currentTarget.value.trim();

            }
            e.currentTarget.value = "";
        }
    }

    const getCurrentFilterState = () => {
        const stateValue = sidebarFilters.senderRecipient[filterName as keyof SenderRecipient];
        return sidebarFilters.senderRecipient[filterName as keyof SenderRecipient] && Array.isArray(stateValue) ?
            stateValue : [];
    }

    const renderItems = () => {
        return getCurrentFilterState()
            .map((item: string) => <Tag data-cy={`${dataCy}-item`} key={item} onClose={() => removeFromState(item)} closable>{item}</Tag>)
    }

    return (
        <div className={className}>
            {renderItems()}
            <Input
                // @ts-ignore
                value={inputValue} onChange={(event: React.KeyboardEvent<HTMLInputElement>) => handleFormValueChange(event)}
                tabIndex={tabIndex}
                placeholder={placeholder}
                data-cy={dataCy}
                className={inputClassName}
                onKeyDown={handleKeyDown}
            />
        </div>
    )
}



