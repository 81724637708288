import React from "react";

import {RouterConfig} from "./navigation/RouterConfig";
import styled from "styled-components";
import {BrowserRouter} from "react-router-dom";

const StyledApp = styled.div`

`;


function App() {

    return (
        <StyledApp>
            <BrowserRouter basename="/">
                <RouterConfig />
            </BrowserRouter>
        </StyledApp>
    );
}

export default App;
