import * as React from 'react';
import {useState} from 'react';
import {StyledHeader} from "../style/filter-component-styling";
import {Form} from "semantic-ui-react";
import {StyledFocusCheckbox} from "../../../../styles/styled-components/styled-focus-checkbox";
import {StyledFocusMultipleValueInput} from "../../../../styles/styled-components/styled-focus-multiple-value-input";
import InfoPopup from "../InfoPopup";
import {IGNORE_TOOLTIP_TEXT} from "../../../../components/CONSTANTS";
import {useRecoilState} from "recoil";
import {sideBarFilterState} from "../../../../states/filtersState";

export const FilterSenderRecipientComponent = () => {

    const [sidebarFilters, setSidebarFilters] = useRecoilState(sideBarFilterState);

    const [validation, setValidation] = useState({
        isSenderValid: true,
        isRecipientValid: true
    });

    const changeAndOr = (event: React.FormEvent<HTMLInputElement> | null, value: { value: string; }) => {
        setSidebarFilters(prevState => ({
            ...prevState,
            senderRecipient: {
                ...prevState.senderRecipient,
                and: value.value === 'and',
            }
        }))
    }

    return (
        <div className='sender-recipient'>
            <StyledHeader as='h6' data-cy='sender-header'>
                Sender
                <InfoPopup content={IGNORE_TOOLTIP_TEXT} />
            </StyledHeader>
            <StyledFocusMultipleValueInput
                filterName={"senders"}
                isValid={validation.isSenderValid}
                setIsValid={(isValid: boolean) => setValidation(prevState => ({ ...prevState, isSenderValid: isValid }))}
                placeholder={sidebarFilters?.senderRecipient?.senders?.length ? "Add" : "All"}
                inputClassName={`custom-multiple-input ${validation.isSenderValid ? '' : 'error'}`}
                dataCy="sender-input"
                tabIndex={5}
            />
            {!validation.isSenderValid && (
                <span data-cy="sender-input-error" className="validation-error">Minimum 3 characters</span>
            )}
            <Form>
                <Form.Field>
                    <StyledFocusCheckbox
                        label='And'
                        name='radioGroup'
                        value='and'
                        checked={!!sidebarFilters?.senderRecipient?.and}
                        onChange={changeAndOr}
                        className='as-radio'
                        tabIndex='6'
                        data-cy="and-radio"
                    />
                </Form.Field>
                <Form.Field className='pdn-left-30'>
                    <StyledFocusCheckbox
                        label='Or'
                        name='radioGroup'
                        value='or'
                        checked={!sidebarFilters?.senderRecipient?.and}
                        onChange={changeAndOr}
                        className='as-radio'
                        tabIndex='7'
                        data-cy="or-radio"
                    />
                </Form.Field>
            </Form>
            <StyledHeader as='h6' data-cy='recipient-header'>
                Recipient
                <InfoPopup content={IGNORE_TOOLTIP_TEXT} />
            </StyledHeader>
            <StyledFocusMultipleValueInput
                isValid={validation.isRecipientValid}
                filterName={"recipients"}
                setIsValid={(isValid: boolean) => setValidation(prevState => ({ ...prevState, isRecipientValid: isValid }))}
                placeholder={sidebarFilters?.senderRecipient?.recipients?.length ? "Add" : "All"}
                inputClassName={`custom-multiple-input ${validation.isRecipientValid ? '' : 'error'}`}
                dataCy="recipients-input"
                tabIndex={8}
            />
            {!validation.isRecipientValid && (
                <span data-cy="recipients-input-error" className="validation-error">Minimum 3 characters</span>
            )}
        </div>
    );
};
