import styled from "styled-components";
import {Table} from "semantic-ui-react";

export const StyledComponentTable = styled.div`
  width: 100%;
  .failed {
    background-color: #FFCDD2;
  }

  .copy-icon-cell {
    padding-right: 15px !important;
    position: relative;

    span {
      position: absolute;
      top: 3px;
      right: 10px;
    }

    .copy-icon {
      cursor: pointer;
    }
  }
`;

export const TableCustomStyles = styled.div`
  max-height: calc(100vh - 150px);
  margin-top: 15px;
  overflow-x: auto;
  white-space: nowrap;
`;

export const TableStyles = styled(Table)`
  white-space: normal;
    .top-header{
        position: sticky;
        top: 0;
        background: white;
        z-index: 1;
    }
  tbody tr:nth-child(2n), &.ui.striped.table > tr:nth-child(2n) {
    background-color: #f3f8ff;
  }
  &.ui.sortable.table {
    thead th{
      color: #084d86;
      font-size: 16px;
    }
    th, td{
      padding: 5px 10px;
      border-left: 1px dashed rgba(34,36,38,.1);
      border-right: none;
      word-wrap: break-word !important;
        max-width: 250px;
        text-align: right;
    }
    tbody tr{
      &:hover{
        background-color: #fff0da;
        border-color: #ffe3b8;
      }
    }
  }
`;
