import React from "react";
import styled from 'styled-components';

import {useOktaAuth} from "@okta/okta-react";

const StyledNavBar = styled.div`
  .navbar-container{
    margin: auto;
    padding: 20px;
    text-align: right;
    
    button{
      color: #084d86;
      background-color: transparent;
      border: none;
      font-style: italic;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .navbar-container a{
    margin-right: 20px;
    color: rgba(0,0,0,.55);
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    -webkit-transition: -webkit-box-shadow .15s ease-in-out;
  }
`;

export const NavLinks = () => {
    const { authState, oktaAuth } = useOktaAuth();

    if (!authState) {
        return null;
    }

    const loginWithRedirect = () => oktaAuth?.signInWithRedirect();

    const logOut = async () => {
        await oktaAuth.signOut({postLogoutRedirectUri: process.env.REACT_APP_HOST});
    };

    const buttonText = authState?.isAuthenticated ? "Logout" : "Login";
    const btnLogic = authState?.isAuthenticated ? logOut : loginWithRedirect;


    return (
        <StyledNavBar>
            <div className="navbar-container">
                <button onClick={btnLogic} data-cy="logout-btn">{buttonText}</button>
            </div>
        </StyledNavBar>
    );
};
