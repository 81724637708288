import * as React from 'react';
import styled from 'styled-components';
import {Header} from 'semantic-ui-react'
import {ExportFileComponent} from "./ExportFileComponent";
import PropTypes from "prop-types";
import {NavLinks} from "../../../components/NavLinks";
import {Filters} from "../../../types/filtersType";

const StyledTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 15px;

  .result-count {
    color: #084d86;
  }

  .header {
    margin: 0;
    align-self: center;
  }
`;
const TableContainerStyle = styled.div`
  max-width: calc(100vw - 50px);

  &.toggle-sidebar {
    max-width: calc(100vw - 380px);
  }
`;

type HeaderProps = {
    toggleSidebar: string,
    numberOfRecords: number,
    filters: Filters
}

export const TableHeaderComponent = ({toggleSidebar, numberOfRecords, filters}: HeaderProps) => {
    const results = (numberOfRecords === 1) ? 'Result' : 'Results';

    return (
        <TableContainerStyle className={(toggleSidebar ? "toggle-sidebar " : "")}>
            <NavLinks/>
            <StyledTableHeader>
                <Header size='medium' className='result-count'>{numberOfRecords} {results}</Header>
                <div>
                    {numberOfRecords > 0 && <ExportFileComponent filters={filters}/>}
                </div>
            </StyledTableHeader>
        </TableContainerStyle>
    );
};

TableHeaderComponent.propTypes = {
    toggleSidebar: PropTypes.bool.isRequired
}