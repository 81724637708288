import React from "react";
import {Header} from "semantic-ui-react";
import {ErrorType} from "../types/errorType";
import {MessageStyled} from "./style/error-component-styling";

type ErrorProps = {
    error: ErrorType,
    classProperty: string
}

export const ErrorComponent = ({error, classProperty}: ErrorProps) => {
    return (<MessageStyled className={classProperty}>
        <div className='error-container'>
            <Header as='h1'>{error?.name}</Header>
            <Header as='h2'>{error?.errorSummary}</Header>
        </div>
    </MessageStyled>);
};
