import * as React from 'react';
import {useCallback, useEffect} from 'react';
import {Button, Icon, Image} from "semantic-ui-react";
// @ts-ignore
import logo from './../../../assets/MedAllies_Logo_vector.svg';
import {StyledCollapseButton, StyledFilters, StyledHeader} from './style/filter-component-styling'
import {FilterSenderRecipientComponent} from "./filter-components/FilterSenderRecipientComponent";
import {KEYBOARDS_BUTTONS} from "../../../components/CONSTANTS";
import {StyledFocusDropdown} from "../../../styles/styled-components/styled-focus-dropdown";

import * as PropTypes from 'prop-types'
import {option} from "../../../types/filtersType";
import {TextInput} from "../../../components/TextInput";
import {DropdownProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import {FilterDateTimeComponent} from './filter-components/FilterDateTimeComponent';
import {defaultFilterValue} from "../../../services/defauldFilterObject";
import {useRecoilState} from "recoil";
import {filterState, sideBarFilterState} from "../../../states/filtersState";
import {LoadingSpinner} from "../../../components/LoadingSpinner";

type FilterProps = {
    setToggleSidebar: (value: (prevState: boolean) => boolean) => void,
    states: option[],
    dispositions: option[],
    dropdownLoading: boolean
}

export const FilterComponent = ({setToggleSidebar, states, dispositions, dropdownLoading}: FilterProps) => {

    const env = process.env.REACT_APP_APP_ENV;
    const [filters, setFilters] = useRecoilState(filterState);
    const [sidebarFilters, setSidebarFilters] = useRecoilState(sideBarFilterState);

    const sidebarToggle = () => {
        setToggleSidebar((prevState: boolean) => (!prevState))
    }

    const chooseDropdownOption = (e: React.FormEvent<HTMLInputElement>, data: DropdownProps) => {
        if (data['data-cy']?.includes("states-dropdown")) {
            setFilterData(data.value as string[], 'states');
        } else {
            setFilterData(data.value as string[], 'dispositions');
        }
    }

    const setFilterData = (data: string[] | Date[], selector: string) => {
        if (data?.length === 1) {
            if (selector === 'states' || selector === 'dispositions') {
                setSidebarFilters(prevState => ({...prevState, [selector]: data}));
            } else {
                setSidebarFilters(prevState => ({...prevState, [selector]: data[0]}));
            }
        } else if (data?.length > 1) {
            setSidebarFilters(prevState => ({...prevState, [selector]: data}));
        } else {
            setSidebarFilters(prevState => ({...prevState, [selector]: null}));
        }
    }

    const applyFilters = () => {
        setFilters(prevState => ({...prevState, ...sidebarFilters, offset: 0}));
    }

    const clearFilters = () => {
        setSidebarFilters(defaultFilterValue);
        setFilters(defaultFilterValue);
    }

    let tabElements = document.querySelectorAll('[tabindex]:not([tabindex="-1"]):not([tabindex="0"])')
    // @ts-ignore
    const handleKeyboard = useCallback(event => {
        if (event?.which === KEYBOARDS_BUTTONS.ENTER_KEY && event?.target?.getAttribute('type') === 'checkbox') {
            event.target.click();
        }

        if (event.which !== KEYBOARDS_BUTTONS.ARROW_RIGHT_KEY &&
            event.which !== KEYBOARDS_BUTTONS.TAB_KEY &&
            event.which !== KEYBOARDS_BUTTONS.ARROW_LEFT_KEY) {
            return;
        }

        let tabIndex = event.target.getAttribute('tabindex');
        if (event.which === KEYBOARDS_BUTTONS.ARROW_RIGHT_KEY) {
            tabIndex++;
            if (tabIndex > tabElements.length) {
                tabIndex = 1;
            }
        }
        if (event.which === KEYBOARDS_BUTTONS.ARROW_LEFT_KEY) {
            tabIndex--;
            if (tabIndex === 0) {
                tabIndex = tabElements.length;
            }
        }

        tabElements.forEach(tabElement => {
            if (parseInt(tabElement.getAttribute('tabindex') as string) === parseInt(tabIndex)) {
                (tabElement as HTMLElement)?.focus();
            }
        })
    }, [tabElements]);

    useEffect(() => {
        const dateStart = document.querySelectorAll('[data-cy="date-picker-start"] input');
        const timeStart = document.querySelectorAll('[data-cy="time-picker-start"] input');
        const dateEnd = document.querySelectorAll('[data-cy="date-picker-end"] input');
        const timeEnd = document.querySelectorAll('[data-cy="time-picker-end"] input');
        dateStart[0].setAttribute("tabindex", "1");
        timeStart[0].setAttribute("tabindex", "2");
        dateEnd[0].setAttribute("tabindex", "3");
        timeEnd[0].setAttribute("tabindex", "4");
        tabElements = document.querySelectorAll('[tabindex]:not([tabindex="-1"]):not([tabindex="0"])');
        tabElements.forEach($coin => $coin.addEventListener('keydown', handleKeyboard));
    }, [])

    const isValidDate = () => {
        const invalidDate: (Date | null)[] = []
        sidebarFilters.date.forEach((val) => {
            if (!val?.valueOf()) {
                invalidDate.push(val)
            }
        });
        sidebarFilters.time.forEach((val) => {
            if (!val?.valueOf()) {
                invalidDate.push(val)
            }
        })
        return !!invalidDate.length;
    }

    return (
        <StyledFilters>
            <div className="sidebar-component">
                <div className='filter-header'>
                    <div className='logo'>
                        <Image src={logo} size='small'/>
                        <span className="env-label">Environment: <span>{env}</span></span>
                    </div>
                </div>
                <div className='filters'>
                    <FilterDateTimeComponent/>
                    <FilterSenderRecipientComponent/>

                    <div className='rest-filters'>
                        <div className="dropdownWithLoading">
                            {dropdownLoading ? <LoadingSpinner/> :
                                <div>
                                    <StyledHeader as='h6'>Disposition Types</StyledHeader>
                                    <StyledFocusDropdown
                                        placeholder='All'
                                        fluid
                                        multiple selection
                                        // forceSelection={false}
                                        defaultValue={filters.dispositions}
                                        onChange={chooseDropdownOption}
                                        data-cy="dispositions-dropdown"
                                        selectOnBlur={false}
                                        options={dispositions}
                                        tabIndex='9'
                                    />
                                </div>}
                        </div>
                        <div className="dropdownWithLoading">
                            {dropdownLoading ? <LoadingSpinner/> :
                                <div>
                                    <StyledHeader as='h6'>Msg States</StyledHeader>
                                    <StyledFocusDropdown
                                        placeholder='All'
                                        fluid
                                        multiple selection
                                        // forceSelection={false}
                                        selectOnBlur={false}
                                        defaultValue={filters.states}
                                        onChange={chooseDropdownOption}
                                        options={states}
                                        data-cy="states-dropdown"
                                        tabIndex='10'
                                    />
                                </div>}
                        </div>
                        <div>
                        </div>
                        {/*<div>*/}
                        {/*    <StyledHeader as='h6'>Failure Reasons</StyledHeader>*/}
                        {/*    <TextInput inputLabel="All" dataTestAttr="all-failure-reasons"*/}
                        {/*               dataTabIndex='11'*/}
                        {/*               dataValue="failureReason" setSidebarFilters={setSidebarFilters}*/}
                        {/*               sidebarFilters={sidebarFilters}/>*/}
                        {/*</div>*/}
                        <div>
                            <StyledHeader as='h6'>XD Msg IDs</StyledHeader>
                            <TextInput inputLabel="All" dataTestAttr="all-xd-msg-ids" dataTabIndex='11'
                                       dataValue="xdMessageId" setSidebarFilters={setSidebarFilters}
                                       sidebarFilters={sidebarFilters}/>
                        </div>
                        <div>
                            <StyledHeader as='h6'>SMTP Msg IDs</StyledHeader>
                            <TextInput inputLabel="All" dataTestAttr="all-smtp-msg-ids" dataTabIndex='12'
                                       dataValue="smtpMessageId" setSidebarFilters={setSidebarFilters}
                                       sidebarFilters={sidebarFilters}/>
                        </div>
                    </div>
                    <div className='filters-footer'>
                        <Button className='clear' data-cy="clear-filter" onClick={clearFilters} tabIndex='13'>Clear
                            Filters</Button>
                        <Button className='apply' data-cy="apply-filter" primary onClick={applyFilters}
                                disabled={isValidDate()}
                                tabIndex='14'>Apply
                            Filters</Button>
                    </div>
                </div>
            </div>
            <StyledCollapseButton className='collapse-button'
                                  onClick={sidebarToggle}>
                <Icon fitted name='caret left'/>
            </StyledCollapseButton>
        </StyledFilters>
    );
};

FilterComponent.propTypes = {
    setToggleSidebar: PropTypes.func
}
