import {Filters} from "../types/filtersType";

const getCurrentDate = () => {
    const startDate = new Date().setHours(0, 0);
    const endDate = new Date().setHours(23, 59);
    return [new Date(startDate), new Date(endDate)];

}

export const defaultFilterValue: Filters = {
    offset: 0,
    limit: 1000,
    time: getCurrentDate(),
    date: getCurrentDate(),
    xdMessageId: "",
    smtpMessageId: "",
    failureReason: "",
    dispositions: [],
    states: [],
    senderRecipient: {
        senders: [],
        recipients: [],
        sendersExclude: [],
        recipientsExclude: [],
        and: true,
    },
    attachments: []
}

