import React, {useEffect} from "react";
import DistilledLogViewer from "../pages/DistilledLogViewer";
import {ROOT} from "./CONSTANTS";

import {Route, Routes, useNavigate} from "react-router-dom";
import {LoginCallback, Security} from "@okta/okta-react";
import {OktaAuth, toRelativeUrl} from "@okta/okta-auth-js";
import {ErrorComponent} from "../components/ErrorComponent";
import {SecureRoute} from "../components/SecureRoute";

const config = {
    issuer: process.env.REACT_APP_ISSUER,
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_LOGIN_CALLBACK}`,
    scopes: process.env.REACT_APP_SCOPES.split(','),
    pkce: true
};

if (!config.scopes || !config.clientId || !config.issuer || !config.redirectUri) {
    throw new Error("All environmental variables must be set");
}

const oktaAuth = new OktaAuth(config);

export const RouterConfig = () => {

    const history = useNavigate();
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history(toRelativeUrl(originalUri || "/", window.location.origin));
    };

    const navigate = useNavigate();

    useEffect(() => {
        oktaAuth.tokenManager.get("accessToken").then(token => {
            /**
             * @type {Date}
             * new Date().getTime()/1000 - remove milliseconds from calculation
             * (currentTime - accessTokenLife) > 900 - update token only when expired time will be less that 15min (900sec)
             */
            if (token) {
                const accessTokenLife = new Date(oktaAuth.tokenManager?.getExpireTime(token));
                const currentTime = new Date().getTime() / 1000;
                if ((accessTokenLife - currentTime) < 900) {
                    oktaAuth.tokenManager.renew("accessToken").then(e => console.log(e))
                    oktaAuth.tokenManager.renew("idToken").then(e => console.log(e))
                }
            }

        })
    }, [navigate]);

    oktaAuth.tokenManager.on('expired', () => {
        logOut().catch(console.error);
    });

    const logOut = async () => {
        await oktaAuth.signOut({postLogoutRedirectUri: process.env.REACT_APP_HOST});
    }

    return (
        <Security
            postLogoutRedirectUri={process.env.REACT_APP_HOST}
            redirectUri={process.env.REACT_APP_HOST}
            oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Routes>
                <Route path="/login/callback" element={<LoginCallback errorComponent={(error) => {
                    if (error.error["errorCode"] === "INTERNAL") {
                        oktaAuth.signInWithRedirect({originalUri: process.env.REACT_APP_HOST});
                        return (<div></div>)
                    } else {
                        const errorObject = {
                            name: error.error["name"],
                            errorSummary: error.error["errorSummary"]
                        }
                        return (<ErrorComponent error={errorObject} classProperty=""/>)
                    }
                }}/>}>
                </Route>
                <Route element={<SecureRoute/>}>
                    <Route path={ROOT} element={<DistilledLogViewer/>}/>
                    <Route path='*' element={<DistilledLogViewer/>}/>
                </Route>
            </Routes>
        </Security>);

};
