import axios from 'axios';
import { Filters, FiltersPayload } from "../types/filtersType";

export const getAllMessages = (url: string, body: Filters) => {
    const getStorageToken = JSON.parse(localStorage.getItem('okta-token-storage') as string);
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getStorageToken.accessToken.accessToken}`
    }

    return axios({
        method: 'post',
        url,
        headers,
        data: body
    })
        .then(res => res.data)
        .catch(function (error) {
            console.log(error);
        })
}

export const getMessage = (url: string) => {
    const getStorageToken = JSON.parse(localStorage.getItem('okta-token-storage') as string);
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getStorageToken.accessToken.accessToken}`
    }

    return axios({
        method: 'get',
        url,
        headers
    })
        .then(res => res.data)
        .catch(function (error) {
            console.log(error);
        })
}

export const getDropdownComponents = (url: string) => {
    const getStorageToken = JSON.parse(localStorage.getItem('okta-token-storage') as string);
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getStorageToken.accessToken.accessToken}`
    }
    return axios({
        method: 'get',
        url,
        headers
    })
        .then(res => res.data)
        .catch(function (error) {
            console.log(error);
        })
}

export const getCSVFile = (url: string, filter: FiltersPayload | null, columns: string[]) => {
    const getStorageToken = JSON.parse(localStorage.getItem('okta-token-storage') as string);
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getStorageToken.accessToken.accessToken}`
    }
    return axios({
        method: 'post',
        url,
        headers,
        responseType: 'blob',
        data: {
            filter,
            columns,
            timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    })
        .then(response => {
            const filename = response.headers['content-disposition'].split('filename="')[1].split('"')[0];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(function (error) {
            console.log(error);
        })
}

export const getAttachment = (url: string) => {
    const getStorageToken = JSON.parse(localStorage.getItem('okta-token-storage') as string);
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getStorageToken.accessToken.accessToken}`
    }
    return axios({
        method: 'get',
        url,
        headers,
        responseType: 'blob',
    })
        .then(response => {
            const filename = response.headers['content-disposition'].split('filename="')[1].split('"')[0];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(function (error) {
            console.log(error);
        })
}
